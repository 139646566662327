import React from "react"

const handleformSubmit = event => {
  event.preventDefault()
  let signUpForm = document.getElementById("sign-up-form")
  let formData = new FormData(signUpForm)

  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  })
    .then(data => {
      let successTemplate = `<div class="notification" style="display:block; opacity: 1;">
		  <div class="notification-dialog">
			<h3><span class="icon"></span>Success !</h3>
			<p>Thanks for reaching out. </p>
			<p>Our team will get in touch with you soon.</p>
		  </div>
		</div>`
      if (data.status == 200) {
        signUpForm.reset()
        document.querySelector(".notification-wrap").innerHTML = successTemplate
        setTimeout(function () {
          document.querySelector(".notification").remove()
        }, 3000)
      }
    })
    .catch(error => alert(error))
}

const SignUpForm = ({ show, close }) => {
  return (
    <div
      className="sign-up-form-section"
      style={{
        display: show ? "block" : "none",
        opacity: show ? "1" : "0",
      }}
    >
      <div className="signup-form-header">
        <h3 className="text signup-heading">Sign up now</h3>
        <span onClick={close}>&times;</span>
      </div>
      <form
        className="sign-up-form"
        data-netlify-recaptcha="true"
        id="sign-up-form"
        data-netlify="true"
        name="signUpForm"
        onSubmit={handleformSubmit}
      >
        <div className="row g-0">
          <div className="col-md-12 form-field">
            <div className="mat-group">
              <input type="text" id="name" name="name" required="required" />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label htmlFor="name">Name*</label>
            </div>
          </div>
          <div className="col-md-12 form-field">
            <div className="mat-group">
              <input type="text" id="email" name="email" required="required" />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label htmlFor="email">Email*</label>
            </div>
          </div>
          <div className="col-md-12 form-field">
            <div className="mat-group">
              <input
                type="text"
                name="storeUrl"
                id="storeUrl"
                required="required"
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label htmlFor="storeUrl">Store Url*</label>
            </div>
          </div>
          <div className="col-md-12 form-field">
            <div className="mat-group ">
              <select required id="currentPlatform" name="currentPlatform">
                <option defaultValue hidden></option>
                <option value="Shopify Advanced">Shopify Advanced</option>
                <option value="Shopify Regular">Shopify Regular</option>
                <option value="Magento CE">Magento CE</option>
                <option value="Magento Enterprise"> Magento Enterprise</option>
                <option value="WooCommerce">WooCommerce</option>
              </select>
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="select-label" htmlFor="currentPlatform">
                Current Platform*
              </label>
            </div>
          </div>
          <div className="col-md-12 form-field">
            <div className="mat-group ">
              <select required id="gmv" name="gmv">
                <option defaultValue hidden></option>
                <option value="$500,000-$750,000">$500,000-$750,000</option>
                <option value="$750,000-$1M">$750,000-$1M</option>
                <option value="$1M and above">$1M and above</option>
              </select>
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="select-label" htmlFor="gmv">
                Select your GMV*
              </label>
            </div>
          </div>
          <div className="col-md-12 form-field">
            <div className="mat-group">
              <select required id="migration" name="migration">
                <option defaultValue hidden></option>
                <option value="Less than 1 month">Less than 1 month</option>
                <option value="1-2 months">1-2 months</option>
                <option value="> 3 months"> &gt; 3 months</option>
              </select>
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="select-label" htmlFor="migration">
                When are you planning the migration?*
              </label>
            </div>
          </div>
          <div className="col-md-12 form-field">
            <div className="mat-group">
              <textarea
                type="textarea"
                rows="3"
                required="required"
                name="bottleneck"
                id="bottleneck"
              ></textarea>
              <span className="highlight"></span>
              <span className="bar"></span>
              <label className="bottleneck-textbox" htmlFor="bottleneck">
                What is your current operational bottleneck?*
              </label>
            </div>
          </div>
        </div>
        <div data-netlify-recaptcha="true"></div>
        <input type="hidden" name="signup-form" value="signUpForm"></input>
        <button className="sign-up-btn" type="submit">
          Sign Up
        </button>
        <div className="notification-wrap signup-notification"></div>
      </form>
    </div>
  )
}

export default SignUpForm
