import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image/withIEPolyfill"
import MigrationPageContent from "../components/MigrationPageContent"
import CrButton from "../components/arrowButton"
import SignUpForm from "../components/signup"
import ArrowBlack from "../images/right-arrow.svg"

import "../styles/signup_promo.scss"
const MigrationOfferPage = () => {
  function signupFormOpen() {
    return setShowForm(true)
  }
  const [showForm, setShowForm] = useState(false)
  const closeModalHandler = () => setShowForm(false)
  const SignUpPromoImages = useStaticQuery(graphql`
    query Images2 {
      banner_image: file(relativePath: { eq: "LandingPage_banner.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decor_image: file(relativePath: { eq: "LandingPage_decor.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gmv_icon: file(relativePath: { eq: "gmv.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ecommerce_icon: file(relativePath: { eq: "e-commerce.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout data={{ headerType: "transparent" }} isSignup={true}>
      <SEO title="Services | Magento to Shopify Migration" />
      <div className="landing-page-wrapper ">
        <div className="hero-banner container-fluid g-0">
          <div className="row banner-columns justify-content-md-between g-0">
            <div className="col-lg-7  banner-text-column align-self-md-center extra-gutter left right">
              <h1
                className="text text-fw-regular text-title-font gap-1x"
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                Migrating from <strong>Magento to Shopify Plus?</strong> We’ll
                cover your first 3 months!
              </h1>
              <p
                className="banner-text text text-black1 gap-1x extra-gutter right"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                We’re covering 3 months of <strong>Shopify Plus charges</strong>{" "}
                for <br className="word-break" /> 5 lucky businesses.
              </p>
              <div
                className="d-flex align-items-center"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <p className="banner-eligibility-text gap-1x text-fw-medium">
                  Check your eligibility
                </p>
                <div className="arrow-icon gap-1x">
                  <img src={ArrowBlack} alt="" />
                </div>
              </div>
            </div>

            <div className="banner-image-column col-lg-5 col-sm-12">
              <Img
                fluid={SignUpPromoImages.banner_image.childImageSharp.fluid}
                alt="Banner mask"
              />
              <div className="banner-mask-overlay"></div>
              <div className="banner-overlay">
                <div
                  className="context"
                  style={{
                    display: showForm ? "none" : "block",
                  }}
                >
                  <h3 className="text-fw-bold banner-overlay-heading">
                    Here’s how it works.
                  </h3>
                  <p className="text-p-md qualify-text">
                    Your business will qualify if:
                  </p>
                  <div
                    data-sal="slide-up"
                    data-sal-delay="100"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="decor-image"
                  >
                    <Img
                      fluid={
                        SignUpPromoImages.decor_image.childImageSharp.fluid
                      }
                      alt="Decor image"
                    />
                    <div className="requirements requirement1">
                      <div className="requirements-icons">
                        <Img
                          fluid={
                            SignUpPromoImages.gmv_icon.childImageSharp.fluid
                          }
                          alt="gmv"
                        />
                      </div>
                      <p
                        className="qualification text-p-md"
                        data-sal="slide-up"
                        data-sal-delay="200"
                        data-sal-easing="ease"
                        data-sal-duration="800"
                      >
                        Your GMV exceeds $500,000
                      </p>
                    </div>
                    <div className="requirements requirement2">
                      <div className="requirements-icons">
                        <Img
                          fluid={
                            SignUpPromoImages.ecommerce_icon.childImageSharp
                              .fluid
                          }
                          alt="E-commerce"
                        />
                      </div>
                      <p
                        className="qualification text-p-md"
                        data-sal="slide-up"
                        data-sal-delay="200"
                        data-sal-easing="ease"
                        data-sal-duration="800"
                      >
                        You have an existing e-commerce store
                      </p>
                    </div>
                  </div>
                  <div className="signup-btn">
                    <CrButton
                      className="sign-up-btn"
                      btnType="button"
                      type="dark"
                      label="Sign Up"
                      onClick={signupFormOpen}
                    />
                  </div>
                </div>
                <SignUpForm show={showForm} close={closeModalHandler} />
              </div>
            </div>
          </div>
        </div>
        <MigrationPageContent></MigrationPageContent>
        <div className="qualification-requirements-section">
          <div className="container-lg">
            <div className="row">
              <div className="col-md-12">
                <div className="section-tag black">
                  <span>How it works</span>
                </div>
                <h3
                  data-sal="slide-up"
                  data-sal-delay="100"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                  className="text text-title-font text-h3 text-fw-regular gap-1x"
                >
                  Here's how you can get started.
                </h3>
                <p
                  data-sal="slide-up"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                  className="text text-p-md text-black3 text-fw-regular gap-2x"
                >
                  Sign up to be one of our 5 lucky winners if
                </p>
              </div>
            </div>
            <div className="qualification-requirements-row row align-items-center extra-gutter right">
              <div className="col-sm-6 col-md-4 qualification-requirements-column gap-1x">
                <div
                  className="requirement-icon"
                  data-sal="slide-up"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                >
                  <Img
                    fluid={SignUpPromoImages.gmv_icon.childImageSharp.fluid}
                    alt="gmv"
                  />
                </div>
                <p
                  className="qualification"
                  data-sal="slide-up"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                >
                  Your GMV exceeds $500,000
                </p>
              </div>
              <div className="col-sm-6 col-md-5 qualification-requirements-column gap-1x">
                <div
                  className="requirement-icon"
                  data-sal="slide-up"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                >
                  <Img
                    fluid={
                      SignUpPromoImages.ecommerce_icon.childImageSharp.fluid
                    }
                    alt="E-Commerce"
                  />
                </div>
                <p
                  className="qualification"
                  data-sal="slide-up"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                >
                  You have an existing e-commerce store
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MigrationOfferPage
